import React, { Fragment } from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import { agencyTheme } from "../../theme/agency";
import { ResetCSS } from "../../assets/css/style";
import {
  GlobalStyle,
  AgencyWrapper,
} from "../../containers/reusableItem/agency.style";
import Navbar from "../../containers/reusableItem/Navbar";
import Footer from "../../containers/reusableItem/Footer";
import { DrawerProvider } from "../../contexts/DrawerContext";
import "@redq/reuse-modal/es/index.css";
import SEO from "../../components/seo";
import Banner from "../../containers/reusableItem/reusableBanner/background";
import BannerContent from "../../containers/reusableItem/reusableBanner/bannerContent";
import Filter from "../../containers/reusableItem/Filtering/DFFAFS";


const FeedADSP = () => {
 
  return (
    <ThemeProvider theme={agencyTheme}>
      <Fragment>
        <SEO title="FeedADSD" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <AgencyWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner>
            <BannerContent title="Feed Additive & Feed Suplement" />
          </Banner>
          <Filter />
          <Footer />
        </AgencyWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default FeedADSP;
